<template>
  <div class="main">
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
    };
  },
  created() {
    
  },
  // components: {
  //   updetaPassword,
  // },
};
</script>

<style lang="less" scoped>

</style>